import Amplify from '@aws-amplify/core'

const configureAmplify = () => {
  // Amplify.configure({
  //   Auth: {
  //     identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  //     region: process.env.REACT_APP_REGION,
  //     identityPoolRegion: process.env.REACT_APP_REGION,
  //     userPoolId: process.env.REACT_APP_USER_POOL_ID,
  //     userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
  //   }
  // })
  Amplify.configure({
    aws_project_region: process.env.REACT_APP_REGION,
    aws_cognito_region: process.env.REACT_APP_REGION,
    aws_user_pools_id: process.env.REACT_APP_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_POOL_WEB_CLIENT_ID
  })
}

export default configureAmplify
