// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import tenants from './tenants'
import sidebar from './sidebar'
import users from './users'

const rootReducer = {
  navbar,
  layout,
  tenants,
  sidebar,
  users
}

export default rootReducer
