import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  selectedTenant: {}
}

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setTenants: (state, action) => {
      return {
        ...state,
        list: action.payload
      }
    },
    addTenant: (state, action) => {
      state.list.push(action.payload)
    },
    setTenant: (state, action) => {
      return {
        ...state,
        list: state.list.map((tenant) => {
          if (tenant?.tenantId && tenant.tenantId === action.payload.tenantId) {
            return action.payload
          }
          return tenant
        })
      }
    },
    setTenantStatusActive: (state, action) => {
      return {
        ...state,
        list: state.list.map((tenant) => {
          if (tenant?.tenantId && tenant.tenantId === action.payload.tenantId) {
            return { ...tenant, isActive: true }
          }
          return tenant
        })
      }
    },
    setTenantStatusInactive: (state, action) => {
      return {
        ...state,
        list: state.list.map((tenant) => {
          if (tenant?.tenantId && tenant.tenantId === action.payload.tenantId) {
            return { ...tenant, isActive: false }
          }
          return tenant
        })
      }
    },
    selectTenant: (state, action) => {
      return {
        ...state,
        selectedTenant: state.list.find((tenant) => tenant.tenantId === action.payload.tenantId)
      }
    },
    deselectTenant: (state) => {
      return {
        ...state,
        selectedTenant: {}
      }
    }
  }
})

export const {
  setTenants,
  setTenant,
  addTenant,
  setTenantStatusActive,
  setTenantStatusInactive,
  selectTenant,
  deselectTenant
} = tenantsSlice.actions

export default tenantsSlice.reducer
