import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: false,
  title: ''
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      return {
        ...state,
        title: action.payload.title
      }
    },
    toggle: (state) => {
      return {
        ...state,
        status: !state.status
      }
    },
    open: (state) => {
      return {
        ...state,
        status: true
      }
    },
    close: (state) => {
      return {
        ...state,
        status: false
      }
    }
  }
})

export const { toggle, open, close, setTitle } = sidebarSlice.actions

export default sidebarSlice.reducer
